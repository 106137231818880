@import '~@fontsource/open-sans/300.css';
@import '~@fontsource/open-sans/400.css';
@import '~@fontsource/open-sans/500.css';
@import '~@fontsource/open-sans/700.css';

html, body, #root {
  height: 100%;
}

* {
  box-sizing: border-box;
  font-family: "Open Sans";
}

.RaLayout-content-5 {
  overflow-x: hidden !important;
}

.RaLayout-root-2 {
  min-width: auto !important;
}

.ra-input {
  // margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ra-rich-text-input {

  &:hover {
    .ql-toolbar.ql-snow, .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-color: #1c1c38 !important;
    }
  }

  .ql-toolbar.ql-snow {
    border: 1px solid rgb(204, 204, 204) !important;
    padding: 8px !important;
    margin: 0 !important;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid rgb(204, 204, 204) !important;
    border-top: 0 !important;
  }

  .outlined .ql-editor {
    padding: 24px !important;
  
    &:before, &:after {
      content: none !important;
    }
  }
}